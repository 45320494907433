import React from 'react'

const Required = () => {
  return (
    <>
    <sup className='invalid'>*</sup>
    </>
  )
}

export default Required;
// export const LOCAL_BASE_URL = "http://192.168.23.3:9000/api/"; //? Abhishek
// export const LOCAL_BASE_URL = "http://192.168.23.37:9000/api/"; //? Sweta
// export const LOCAL_BASE_URL = "http://192.168.23.6:9000/api/"; //?Utkarsh
// export const LOCAL_BASE_URL = "http://3.111.82.65:3000/api/";
export const LOCAL_BASE_URL = "https://alimcoapi.demoquaeretech.in/api/";  //!     DEVELOPMENT
// export const LOCAL_BASE_URL = "https://aftersales.alimco.in/api/api/"; //TODO : PRODUCTION      
// export const LOCAL_BASE_URL2 = "http://35.154.223.248:9000/api/";
export const IMAGE_FOLDER_PATH = "/public/";
export const ENCRYPT_KEY ='3=FOJ`HV)&tB5Jc'
export const TEST_KEY_ID = `rzp_test_tgWgOfXw8Z4eKf`
export const LIVE_KEY =`rzp_live_YFpcXeQgXfT1pv`
 // Example secret key (replace this with a secure method of key management)
export const SECRET_KEY = "S=TzGaKY*;~~0'%";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { aasraTransactionListAPI, paymentList } from "../../api/user";
import MyDataTable from "../../Components/MyComponents/MyDataTable";
import useLogout from "../../util/useLogout";
import { Breadcrumbs } from "../../AbstractElements";
import { RUPEES_SYMBOL } from "../../Constant";
import { Col, Row } from "reactstrap";

const TransactionList = () => {
  const logout = useLogout();
  const [tableData, setTableData] = useState([]);
  const userToken = localStorage.getItem("accessToken");

  const columns = [
    {
      name: "Aasra Name",
      selector: (row) => row.aasraName,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Aasra Code",
      selector: (row) => row.aasraCode,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => RUPEES_SYMBOL + row.amount,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "  Payment ID",
      selector: (row) => row.order,
      sortable: true,
      wrap: true
    },
    {
      name: " Razor-pay Signature",
      selector: (row) => row.razorpay_signature,
      sortable: true,
      wrap: true,
    },
    {
      name: " Razor-pay Payment ID",
      selector: (row) => row.razorpay_payment_id,
      sortable: true,
      wrap:true
    },
    {
      name: "Date and Time",
      selector: (row) => row.date,
      sortable: true,
      wrap:true
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <span
          className={
            row.status == "success"
              ? "badge badge-light-success"
              : row.status == "failed"
              ? "badge badge-light-danger"
              : "badge badge-light-warning"
          }
        >
          {row.status=="success" ? "Success":null}
          {row.status=="failed" ? "Failed":null}
        </span>
      ),
      sortable: true,
    },
  ];
  const onFormSubmit = (data) => {
    const token = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + `${userToken}`,
      },
    };
    const searchData = {
      aasra_id: data.aasraId?.value || null,
      startDate: data.startDate
        ? new Date(
            new Date(data.startDate).setDate(
              new Date(data.startDate).getDate() + 1
            )
          ).toISOString()
        : null,
      endDate: data.endDate
        ? new Date(
            new Date(data.endDate).setDate(new Date(data.endDate).getDate())
          ).toISOString()
        : null,
    };
    aasraTransactionListAPI(searchData, token)
      .then((res) => {
        if (res.data.status === "success") {
          setTableData(res?.data?.data);
          toast.success(res.data.message);
        } else if (res.data.status === "failed") {
          setTableData([]);
          toast.error(res.data.message);
        } else if (res.data.status === "expired") {
          logout(res.message); // Trigger logout for expired status
        }
      })
      .catch((errors) => {
        console.error("API Error:", errors); // Improved error logging
      });
  };

  return (
    <>
      <Breadcrumbs
        mainTitle=" Aasra Transaction Reports"
        parent=""
        title=" Aasra Transaction Reports"
      />
      <Row>
        <Col sm="12">
          <MyDataTable
            export
            SearchCall
            aasraType
            dateFilter
            onFormSubmit={onFormSubmit}
            search="search by Payment Id/Razor-pay payment ID no"
            name="Aasra-Transaction"
            fileName="Aasra Transaction Reports"
            title="Aasra Transaction Reports"
            columns={columns}
            data={tableData}
          />
        </Col>
      </Row>
    </>
  );
};

export default TransactionList;
